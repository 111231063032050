export const tableHeaders = [
  '#',
  'School Code',
  'School Name',
  'School Branch',
  'Student ID/UserID/Adm ID',
  'Student Login Password',
  'Student First Name',
  'Student Last Name',
  'Student Phone Number',
  'Student Email ID',
  'Student Section',
  'Grade',
  'Parent Name',
  'Student DOB',
  'Week of the event',
  'Source of Registration (Organic /Bulk)',
  'Event Name',
  'Event Scheduled date by POCs',
  'Event Start Date and Time for School',
  'Event End Date and Time for School',
  'Logged In  (Yes/No)',
  'Logged In date and time',
  'Questionnaire Status (Yes/No)',
  'Questionnaire Date and Time of Submission',
  'Event Attempted Status of Students (Yes/no)',
  'Event Started date and time of students',
  'Event completed date and time of  students',
  'Duration spent on event (in minutes)',
  'Report Link',
  'Marks Obtained/Total Marks',
  'Certificate Link',
];

export const tableData = [
  [
    '1',
    '123',
    'School A',
    'Branch A',
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    // Add more data here
  ],
  [
    '2',
    '456',
    'School B',
    'Branch B',
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "",
    "",
    "",
    "",
    "venkata shivasai",
    "",
    "",
    // Add more data here
  ],
  // Add more rows as needed
];

export const title = ["Student Onboarding Insights"];