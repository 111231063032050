export const dropdowns = [
    {
      title: 'Date',
      options: ['Last One week', 'Last One month', 'Last three months', 'Custom'],
    },
    {
      title: 'School Name',
      options: ['A-D', 'E-I', 'J-N', '0-Z'],
    },
    {
      title: 'Source of Registration',
      options: ['Organic', 'Bulk', 'Other'],
    },
    {
      title: 'Fee Range',
      options: ['Less than 50K', '50k - 1 Lac', '1 Lac to 2 Lac', 'More than 2 Lac'],
    },
    {
      title: 'Category',
      options: ['Rural', 'Semi Urban', 'Urban'],
    },
    {
      title: 'Tier',
      options: ['Rural', 'Semi Urban', 'Urban'],
    },
  ];