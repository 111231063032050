import React from "react";
import manWithLaptop from "../../../assets/img/illustrations/man-with-laptop-light.png";

export const WhiteCard = () => {
  return (
    <div className="col-7">
      <div className="card">
        <div className="d-flex align-items-end row">
          <div className="col-sm-7">
            <div className="card-body">
              <h5 className="card-title text-primary">
                Congratulations John! 🎉
              </h5>
              <p className="mb-4">
                You have done <span className="fw-bold">12 Schools</span>{" "}
                onboarding today. Check your new badge in your profile.
              </p>
              <a href="javascript:;" className="btn btn-sm btn-outline-primary">
                View Badges
              </a>
            </div>
          </div>
          <div className="col-sm-5 text-center text-sm-left">
            <div className="card-body pb-0 px-0 px-md-4">
              <img
                src={manWithLaptop}
                height={140}
                alt="View Badge User"
                data-app-dark-img="illustrations/man-with-laptop-dark.png"
                data-app-light-img="illustrations/man-with-laptop-light.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
