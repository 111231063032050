export const dropdowns = [
    {
      title: 'Event Scheduled Date for school',
      options: ['Last One week', 'Last One month', 'Last three months', 'Custom'],
    },
    {
      title: 'Event Completed Date by student ',
      options: ['A-D', 'E-I', 'J-N', '0-Z'],
    },
    {
      title: 'Event Name',
      options: ['Organic', 'Bulk', 'Other'],
    },
    {
      title: 'School Name',
      options: ['Less than 50K', '50k - 1 Lac', '1 Lac to 2 Lac', 'More than 2 Lac'],
    },
    {
      title: 'Adm ID',
      options: ['Rural', 'Semi Urban', 'Urban'],
    },
    {
      title: 'Attempted Status',
      options: ['Rural', 'Semi Urban', 'Urban'],
    },
    {
      title: 'Logged In Status',
      options: ['Rural', 'Semi Urban', 'Urban'],
    },
    {
      title: 'Questionnaire Status',
      options: ['Rural', 'Semi Urban', 'Urban'],
    },
    {
      title: 'Grade',
      options: ['Rural', 'Semi Urban', 'Urban'],
    },
  ];