import React from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../constants/routes";
import { Link } from "react-router-dom";

export const sidebarItems = [
  // {
  //   id: 1,
  //   icon: 'bx-home-circle',
  //   text: 'Dashboard',
  //   link: ROUTES.HOME,
  //   data: "Analytics",
  //   active: "active"
  // },
  // {
  //   id: 2,
  //   icon: 'bx-file',
  //   text: 'Download',
  //   link: ROUTES.HOME,
  //   data: "Documentation",
  //   active: ""
  // },
  // {
  //   id: 3,
  //   icon: "bxs-compass",
  //   text: "Explore",
  //   link: ROUTES.EXPLORE_TABLE,
  //   data: "Documentation",
  //   active: "",
  // },
  {
    id: 4,
    icon: "bxs-compass",
    text: "Student",
    link: ROUTES.STUDENT,
    data: "Documentation",
    active: "",
  },
  {
    id: 5,
    icon: "bxs-compass",
    text: "Event",
    link: ROUTES.EVENT,
    data: "Documentation",
    active: "",
  },
];

export const Sidebar = () => {
  const { t } = useTranslation();

  return (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme"
    >
      <div class="app-brand demo">
        <a class="app-brand-link">
          <span class="app-brand-logo demo"></span>
          <span class="app-brand-text demo menu-text fw-bolder ms-2">
            Inmobius
          </span>
        </a>

        <a class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
          <i class="bx bx-chevron-left bx-sm align-middle"></i>
        </a>
      </div>

      <div class="menu-inner-shadow"></div>
      <ul className="menu-inner py-1 ps">
        {sidebarItems.map((item) => (
          <li key={item.id} className={`menu-item ${item.active}`}>
            <Link to={item.link} className="menu-link">
              {" "}
              {/* Use Link component */}
              <i className={`menu-icon tf-icons bx ${item.icon}`} />
              <div data-i18n={`${item.data}`}>{item.text}</div>
            </Link>
          </li>
        ))}
      </ul>
    </aside>
  );
};
