import React, { useState, useEffect } from "react";
import { Card } from "../Cards";
import { cardConfig } from "../config";
import paypal from "../../../../assets/img/icons/unicons/paypal.png";

export const ExamScheduleCard = () => {
  const cardData = cardConfig.find((card) => card.type === "ExamsScheduled");
  const [examCount, setExamCount] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://adminpreprod.inmobiusinfinitylearn.com/api/v1/insights/get-count"
          //"https://inmobiusstaging.devinfinitylearn.in/api/v1/insights/get-count"
        );
        const result = await response.json();
        const totalExamCount = result.data.total_events_count;
        setExamCount(totalExamCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const handleViewMoreClick = () => {
    // Handle View More click
  };

  const handleDeleteClick = () => {
    // Handle Delete click
  };

  const dropdownButtons = [
    {
      text: "View More",
      link: "javascript:void(0);",
      onClick: handleViewMoreClick,
      buttonClassName: "",
    },
    {
      text: "Delete",
      link: "javascript:void(0);",
      onClick: handleDeleteClick,
      buttonClassName: "text-danger",
    },
  ];

  const cardDropdownConfig = {
    buttons: dropdownButtons,
    className: "my-dropdown",
  };

  return (
    <div className="col-2">
      <div>
        <Card
          title={cardData.title}
          iconSrc={paypal}
          value={examCount}
          cardDropdownConfig={cardDropdownConfig}
        />
      </div>
    </div>
  );
};
