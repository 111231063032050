import React, { useState, useEffect } from "react";
import { Sidebar } from "../../Sidebar/Sidebar";
import Dropdown from "../Dropdown";
import Navbar from "../../Navbar/Navbar";
import { dropdowns } from "./DropdownConfig";
import { tableHeaders, title } from "./componentsConfig";

export const headerToApiResponseMapping = {
  "School Code": "school_id",
  "School Name": "school_name",
  "Branch Name": "branch_name",
  "School Type": "school_type",
  "School Board": "board",
  Address: "address",
  Country: "country",
  State: "state",
  City: "city",
  "Pin Code": "pincode",
  "Primary contact Name": "primary_contact_info.name",
  "Primary contact Number": "primary_contact_info.phone",
  "Primary Contact Email Id": "primary_contact_info.email",
  "Primary contact Designation": "primary_contact_info.designation",
  "Secondary contact Name": "secondary_contact_info.name",
  "Secondary contact Number": "secondary_contact_info.phone",
  "Secondary Contact Email Id": "secondary_contact_info.email",
  "Secondary contact Designation": "secondary_contact_info.designation",
  "Total Strength": "total_student_count",
  "School Registered Date and Time": "oplog_date",
  "Source of Registration": "product_id",
  "Annual Fees": "", // This field is not provided in the data
  Category: "", // This field is not provided in the data
  Tier: "", // This field is not provided in the data
  "Manager Name": "", // This field is not provided in the data
  "Referrer Name": "", // This field is not provided in the data
};

const SchoolOnboardingTable = () => {
  const [schoolData, setSchoolData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2OTY5MTYxNjcsInVzZXIiOnsiaWQiOiI2NTAzZTVlOTE5NWEyM2QxMzZhODIzMjAiLCJpc2RfY29kZSI6Iis5MSIsInBob25lIjoiODAwMDAwMDAxMiIsImVtYWlsIjpudWxsLCJhZG1pc3Npb25faWQiOm51bGwsInByb2R1Y3RfaWQiOjEwMCwiY3JuX2lkIjoiQ1JOUDEwMFQ5OTk5OVBaWjVZM0YiLCJyb2xlX2lkcyI6WzFdLCJncmFkZSI6bnVsbCwic2Nob29sX2lkIjpudWxsLCJhY2NvdW50X3R5cGUiOiJ1c2VyIiwiaGFsbF90aWNrZXRfaWQiOm51bGx9LCJ0eXBlIjoiYWNjZXNzIn0.YyJx__bO-cKGYxmn5chDZRV9kQ62EFgHdGy8dH8U_7k";

  const fetchSchoolData = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        "https://inmobiusstaging.devinfinitylearn.in/api/v1/insights/schools",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSchoolData(data?.data.school_data || []);
      } else {
        console.error("Error fetching school data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSchoolData();
  }, []);

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <Sidebar data-bg-class="bg-menu-class" />
        <div className="layout-page">
          <Navbar />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <h4 className="fw-bold py-3 mb-4">
                Welcome to Insights Dashboard
              </h4>
              {dropdowns.map((dropdown, index) => (
                <Dropdown
                  key={index}
                  title={dropdown.title}
                  options={dropdown.options}
                />
              ))}
              <div className="card mt-2">
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          {tableHeaders.map((header, index) => (
                            <th key={index}>{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {schoolData.map((item, index) => (
                          <tr key={index}>
                            {tableHeaders.map((header, index) => (
                              <td key={index}>
                                {item[headerToApiResponseMapping[header]] ||
                                  "-"}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="content-backdrop fade" />
      </div>
      <div className="layout-overlay layout-menu-toggle" />
    </div>
  );
};

export default SchoolOnboardingTable;
