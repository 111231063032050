import React from "react";
import GlobalDropdown from "../Dropdowns/GlobalDropdown";

export const Card = ({ title, iconSrc, value, growth, cardDropdownConfig }) => {
  return (
    <div class="col-lg-12 col-md-12 col-6 mb-4">
      <div className="card">
        <div className="card-body">
          <div className="card-title d-flex align-items-start justify-content-between">
            <div className="avatar flex-shrink-0">
              <img src={iconSrc} alt={title} className="rounded" />
            </div>
            {cardDropdownConfig && (
              <div className="dropdown">
                <GlobalDropdown {...cardDropdownConfig} />
              </div>
            )}
          </div>
          <span className="fw-semibold d-block mb-1">{title}</span>
          <br />
          <h3 className="card-title mb-2">{value}</h3>
         
        </div>
      </div>
    </div>
  );
};
