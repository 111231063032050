import * as yup from "yup";



    export const passwordSchema = yup.string().min(8, "Password field must contain atleast 8 characters").required();
    export const singleselectSchema = yup
        .object({
            label: yup.string().required(),
            value: yup.string().required(),
        })
        .required("select atleast one option");
    export const multiSelectSchema = yup.array().min(1).required();
    export const nameSchema = yup
        .string()
        .min(1, "This field should not be empty")
        .max(50)
        .matches(/^\S+$/, "Spaces are not allowed")
        .required();
    export const nameSpaceSchema = yup
        .string()
        .min(1, "This field should not be empty")
        .max(50)
        .matches(/^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/, "Special characters are not allowed")
        .required();
    export const codeSchema = yup
        .string()
        .min(1, "This field should not be empty")
        .max(5, "Max characters allowed are 5")
        .matches(/^(?=.*[A-Z])?[A-Z0-9]+$/, "Code should be uppercase Eg:ASG123")
        .required();
    export const phoneSchema = yup
        .string()
        .min(10, "Enter valid phone number")
        .max(10)
        .matches(/^\d{10}$/, "Enter valid phone number")
        .required();
    export const productIdSchema = yup
        .string()
        .min(1, "ProductId must be at least 1 digit")
        .max(5)
        .matches(/^\d{1,5}$/, "Only integers with 1 to 5 digits are allowed")
        .required();
    export const emailSchema = yup
        .string()
        .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, "enter valid Email")
        .required();
    export const noSpecCharSchema = yup
        .string()
        .min(1, "This field should not be empty")
        .max(50)
        .matches(/^[a-zA-Z0-9]+$/, "Special characters are not allowed")
        .required();
    export const URLSchema = yup
        .string()
        .matches(/^(ftp|http|https):\/\/[^ "]+$/, "Enter valid URL")
        .required();
    