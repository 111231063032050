import React from 'react';
import NavbarToggle from './NavbarToggle';
import SearchInput from './SearchInput';
import UserDropdown from './UserDropdown';

const Navbar = () => (
  <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
    <NavbarToggle />
    <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
        <SearchInput />
      </div>
        <UserDropdown />
  </nav>
);

export default Navbar;
