export const tableHeaders = [
  'School Code',
  'School Name',
  'Branch Name',
  'School Type',
  'School Board',
  'Address',
  'Country',
  'State',
  'City',
  'Pin Code',
  'Primary contact Name',
  'Primary contact Number',
  'Primary Contact Email Id',
  'Primary contact Designation',
  'Secondary contact Name',
  'Secondary contact Number',
  'Secondary Contact Email Id',
  'Secondary contact Designation',
  'Total Strength',
  'School Registered Date and Time',
  'Source of Registration',
  'Annual Fees',
  'Category',
  'Tier',
  'Manager Name',
  'Referrer Name',
];

export const tableData = [
  [
    '1',
    '123',
    'School A',
    'Branch A',
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    // Add more data here
  ],
  [
    '2',
    '456',
    'School B',
    'Branch B',
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "",
    "",
    "",
    "",
    "venkata shivasai",
    "",
    "",
    // Add more data here
  ],
  // Add more rows as needed
];

export const title = ["School Onboarding Insights"];