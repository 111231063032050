import React, { useState, useEffect } from "react";
import UploadStep from "./UploadStep";
import ValidationStep from "./ValidationStep";
import EditStep from "./EditStep";
import SubmitStep from "./SubmitStep";
import FailedRecordsStep from "./FailedRecordsStep";
import { CONFIG_VARS } from "../../constants/configuration";

export const UploadPopUp = ({
  handleCloseModal,
  schoolId,
  selectedEvent,
  uploadType,
  selectedTab,
}) => {
  const [step, setStep] = useState(0);
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [validData, setValidData] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isValidating, setIsValidating] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false); // State for status button loading
  const [uploadMsg, setUploadMsg] = useState("");
  const [jobId, setJobId] = useState();
  const [uploadStatusData, setUploadStatusData] = useState([]);

  useEffect(() => {
    if (selectedTab !== "Onboarded Students") {
      setStep(1);
    }
  }, [selectedTab]);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleFileChange = (data) => {
    setCsvData(data);
    nextStep();
  };

  const handleValidationResult = (valid, errors) => {
    setValidData(valid);
    setValidationErrors(errors);
    setIsValid(errors.length === 0);
    setIsValidating(false);
  };

  const handleUpdateCsvData = (data) => {
    setCsvData(data);
    nextStep();
  };

  const handleSubmit = () => {
    setLoading(true);
    const url =
      uploadType === "Register"
        ? `${CONFIG_VARS.inmobius}/data-imports/bulk-register?school_id=${schoolId}`
        : `${CONFIG_VARS.inmobius}/data-imports/bulk-update?school_id=${schoolId}`;
    const formData = new FormData();
    formData.append("fileobject", file);
    formData.append(
      "event_scheduled",
      selectedEvent?.scheduled_events.join(",")
    );
    const headers = {
      accept: "application/json",
      "tenant-id": "99999",
    };

    if (uploadType === "Update") {
      const accessToken = localStorage.getItem("accessToken");
      headers["Authorization"] = `Bearer ${accessToken}`;
      headers["product-id"] = "1000";
    } else if (uploadType === "Register") {
      headers["product-id"] = "100";
    }

    fetch(url, {
      method: "POST",
      headers: headers,
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        setLoading(false);
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        setUploadMsg(data?._id);
        setJobId(data?.job_id);
      })
      .catch((error) => {
        console.error("Error:", error);
        setUploadMsg(`Error: ${error?.message}}`);
        setLoading(false);
      });
  };

  const upoloadeStatus = async () => {
    setLoadingStatus(true); // Start loading status
    const url = `${CONFIG_VARS.inmobius}/data-imports/get-bulk-record-status?job_id=${jobId}`;
    const headers = {
      accept: "application/json",
      "product-id": "1000",
      "tenant-id": "99999",
    };
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      if (res.ok) {
        const data = await res.json();
        console.log(data);
        setUploadStatusData(data);
      } else {
        console.error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoadingStatus(false); // Stop loading status
    }
  };

  return (
    <div className="overlay">
      <div className="overlay-content">
        <h1>Import File</h1>
        <div className="line"></div>
        <div className="uploadModal-content">
          <div>
            {step === 0 && selectedTab === "Onboarded Students" && (
              <div>
                <p className="declaration">
                  <span>Attention:</span> This process registers students with
                  the school.
                  <br /> You will need to assign them to events separately from
                  events tab.
                </p>
              </div>
            )}
            {step === 1 && (
              <UploadStep
                setFile={setFile}
                handleFileChange={handleFileChange}
              />
            )}
            {step === 2 && (
              <ValidationStep
                csvData={csvData}
                handleValidationResult={handleValidationResult}
                setIsValidating={setIsValidating}
              />
            )}
            {step === 3 && (
              <EditStep
                csvData={csvData}
                validationErrors={validationErrors}
                handleUpdateCsvData={handleUpdateCsvData}
              />
            )}
            {step === 4 && (
              <SubmitStep
                loading={loading}
                uploadMsg={uploadMsg}
                validData={validData}
                handleSubmit={handleSubmit}
                upoloadeStatus={upoloadeStatus}
                uploadStatusData={uploadStatusData}
                jobId={jobId}
                loadingStatus={loadingStatus} // Pass loadingStatus to SubmitStep
              />
            )}
            {step === 5 && (
              <FailedRecordsStep uploadStatusData={uploadStatusData} />
            )}
          </div>
        </div>
        <div className="popupobutnCont">
          {step < 1 && (
            <button onClick={() => setStep(1)} className="saveBtn">
              OK
            </button>
          )}
          {step < 2 && (
            <button
              onClick={handleCloseModal}
              className="closeBtn"
              disabled={step === 2 && isValidating}
            >
              Close
            </button>
          )}
          {step > 2 && (
            <button
              onClick={handleCloseModal}
              className="closeBtn"
              disabled={step === 2 && isValidating}
            >
              Close
            </button>
          )}
          {step > 1 && (
            <button
              onClick={prevStep}
              className="saveBtn"
              disabled={step === 2 && isValidating}
            >
              Back
            </button>
          )}
          {step === 2 && !isValidating && !isValid && (
            <button onClick={() => setStep(3)} className="saveBtn">
              View
            </button>
          )}
          {step === 2 && !isValidating && isValid && (
            <button onClick={() => setStep(4)} className="saveBtn">
              Next
            </button>
          )}
          {step === 4 && uploadMsg === "" && (
            <button
              onClick={handleSubmit}
              disabled={loading}
              className="saveBtn"
            >
              Submit
            </button>
          )}
          {step === 4 && uploadStatusData?.status === "failed" && (
            <button onClick={() => setStep(5)} className="saveBtn">
              View Failed Records
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
