const participationItems = [
    {
      icon: 'bx bx-mobile-alt',
      title: 'PSB School',
      subtitle: 'Hyderabad-Ammerpet',
      progress: '45 out of 234',
    },
    {
      icon: 'bx bx-closet',
      title: 'Oyseter Int. School',
      subtitle: 'Karnataka-Dharwad',
      progress: '124 out of 200',
    },
    {
      icon: 'bx bx-home-alt',
      title: 'DPS',
      subtitle: 'Delhi- Cannought Circle',
      progress: '200 out of 220',
    },
    {
      icon: 'bx bx-football',
      title: 'DAV',
      subtitle: 'Gwalior',
      progress: '54 out of 120',
    },
    // Add more items as needed
  ];
  
  export default participationItems;
  