import React from 'react';
import Widget from '../Widget';
import ParticipationItem from './ParticipationItem';
import participationItems from './participationConfig';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';

const ParticipationWidget = () => {

  const history = useHistory();

  const handleViewAllClick = () => {
    history.push(ROUTES.PARTICIPATION_TABLE);
  };

  const handleShareClick = () => {
    // Handle Share click
  };

  const handleRefreshClick = () => {
    // Handle Refresh click
  };

  const dropdownItems = [
    { text: 'View All', onClick: handleViewAllClick },
    { text: 'Share', onClick: handleShareClick },
    { text: 'Refresh', onClick: handleRefreshClick }    
  ];

  return (
    <Widget
      title="Participation Report"
      content="64% Participation"
      dropdownItems={dropdownItems}
    >
      <ul className="p-0 m-0">
        {participationItems.map((item, index) => (
          <ParticipationItem
            key={index}
            icon={item.icon}
            title={item.title}
            subtitle={item.subtitle}
            progress={item.progress}
          />
        ))}
      </ul>
    </Widget>
  );
};

export default ParticipationWidget;
