import React from "react";
import "./EditStep.css";

const SubmitStep = ({
  validData,
  handleSubmit,
  uploadMsg,
  loading,
  upoloadeStatus,
  uploadStatusData,
  jobId,
  loadingStatus, // Add loadingStatus prop
}) => {
  const getStatusStyle = (status) => {
    let color;
    switch (status) {
      case "in-progress":
        color = "blue";
        break;
      case "failed":
        color = "red";
        break;
      case "success":
        color = "green";
        break;
      default:
        color = "black"; // default color if none of the conditions match
    }
    return {
      color,
      fontSize: "24px",
    };
  };

  const renderLoader = () => (
    <span className="loader"></span> // Add a loader span or component
  );

  const statusButtonText = loadingStatus ? renderLoader() : "Status";

  return (
    <div>
      {loading ? (
        <h2>Uploading... please Wait!</h2>
      ) : (
        <>
          <h2>{uploadMsg === "" ? "Ready to Submit" : uploadMsg}</h2>
          <p style={getStatusStyle(uploadStatusData?.status)}>
            {uploadStatusData?.status}
          </p>
          {jobId && (
            <button onClick={upoloadeStatus} className="statusBtn saveBtn">
              {statusButtonText}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default SubmitStep;
