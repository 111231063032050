export const tableHeaders = [
  '#',
  'School Code',
  'School Name',
  'School Branch',
  'Event Name',
  'First Schedule Date',
  'Second Schedule Data',
  'Latest Event Scheduled Date',
  'Total Strength',
  'Total signed up (Unique Users)',
  'Total student attempted Questionnaire',
  'Total Attempted',
];

export const tableData = [
  [
    '1',
    '123',
    'School A',
    'Branch A',
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ],
  [
    '2',
    '456',
    'School B',
    'Branch B',
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ],
  // Add more rows as needed
];
export const title = ["Participation Insights"];