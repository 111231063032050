import React from "react";
import Fab from "@mui/material/Fab";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const BacktoTop = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div onClick={scrollToTop} className="back-to-top">
            <Fab size="small" color="primary" aria-label="back-to-top">
                <ArrowUpwardIcon />
            </Fab>
        </div>
    );
};

export default BacktoTop;
