import React, { useState } from "react";

const Table = ({ headers, data, title }) => {
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editingColumnIndex, setEditingColumnIndex] = useState(null);
  const [editedValue, setEditedValue] = useState("");

  const editableColumns = headers.slice(-5); // Columns you want to be editable

  const handleCellClick = (rowIndex, columnIndex, value) => {
    if (editableColumns.includes(headers[columnIndex])) {
      setEditingRowIndex(rowIndex);
      setEditingColumnIndex(columnIndex);
      setEditedValue(value);
    }
  };

  const handleInputChange = (e) => {
    setEditedValue(e.target.value);
  };

  const handleInputBlur = () => {
    if (editingRowIndex !== null && editingColumnIndex !== null) {
      const updatedRow = [...data[editingRowIndex]];
      updatedRow[editingColumnIndex] = editedValue;

      const updatedData = [...data];
      updatedData[editingRowIndex] = updatedRow;

      // Now you need to handle the updatedData accordingly (e.g., update state or API)

      setEditingRowIndex(null);
      setEditingColumnIndex(null);
      setEditedValue("");
    }
  };

  return (
    <div>
      <h5 className="card-header">{title}</h5>
      <div className="table-responsive text-nowrap">
        <table className="table">
          <thead>
            <tr className="text-nowrap">
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    onClick={() => handleCellClick(rowIndex, cellIndex, cell)}
                  >
                    {editingRowIndex === rowIndex &&
                    editingColumnIndex === cellIndex ? (
                      <input
                        type="text"
                        className="form-control"
                        style={{ border: "1px solid #ccc" }} // Add border style
                        value={editedValue}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                      />
                    ) : (
                      cell
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
