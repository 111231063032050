import React, { useEffect, useState } from "react";
import { Sidebar } from "../Sidebar/Sidebar";
import { Backdrop, CircularProgress } from "@mui/material";
import EventTable from "../table/EventTable";
import { CONFIG_VARS } from "../../constants/configuration";
import { UploadPopUp } from "../Modal/UploadPopUp";
import right from "../../../assets/img/Svgs/FrameRight.svg";
import left from "../../../assets/img/Svgs/FrameLeft.svg";
import { tableHeaders } from "../Tables/Explore/componentsConfig";
import { isAuthenticated } from "../../../utils/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../../constants/routes";
import loader from "../../../assets/gif/ZZ5H.gif";
import Navbar from "../Navbar";
import axios from "axios";

const Events = () => {
  const [eventData, setEventData] = useState([]);
  const [students, setStudents] = useState([]);
  const [studentsByEvent, setStudentsByEvent] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Events");
  const [uploadPopup, setUploadPopup] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [schoolId, setSchoolId] = useState("");
  const [uploadType, setUploadType] = useState("Register");
  const [getDataLoader, setDataLoader] = useState(false);
  const [tabLoader, setTabLoader] = useState(false);
  const [eventLoader, setEventLoader] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [totalPages, setTotalPages] = useState(2);
  const [totalCount, setTotalCount] = useState(0);
  const [roleInfo, setRoleInfo] = useState([]);

  const history = useHistory();
  useEffect(() => {
    if (!isAuthenticated()) {
      history.push(ROUTES.LOGIN);
    }
  }, []);

  useEffect(() => {
    const fetchStudentsByEvent = () => {
      setEventLoader((prev) => ({ ...prev, [selectedEvent?.event]: true })); // Start the loader for the specific event
      const data = { event_ids: selectedEvent?.scheduled_events };

      fetch(
        `${CONFIG_VARS.inmobius}/insights/students_by_event_ids?page=${currPage}&limit=${limit}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "product-id": "100",
            "tenant-id": "99999",
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setStudentsByEvent(data?.data?.students || []);
          setTotalCount(data?.data?.total_student_count);
          setEventLoader((prev) => ({
            ...prev,
            [selectedEvent?.event]: false,
          }));
        })
        .catch((error) => {
          console.error("Error:", error);
          setEventLoader((prev) => ({
            ...prev,
            [selectedEvent?.event]: false,
          }));
        });
    };

    selectedEvent && fetchStudentsByEvent();
  }, [selectedEvent, currPage, limit]);
  const isEventUploadEnabled = (createdDate) => {
    const eventDate = new Date(createdDate);
    const currentDate = new Date();
    const diffDays = Math.floor(
      (currentDate - eventDate) / (1000 * 60 * 60 * 24)
    );
    return diffDays <= 30;
  };

  const formatDateTime = (dateTimeString) => {
    if (dateTimeString === "-") return "-";

    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const fetchEvents = () => {
    setTabLoader(true);
    if (schoolId === "") {
      setTabLoader(false);
      return;
    }
    fetch(
      `${CONFIG_VARS.inmobius}/insights/events_by_school?school_id=${schoolId}&page=1&limit=99`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "product-id": "100",
          "tenant-id": "99999",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const UE = enrichEventsWithStatus(data?.data);
        setEventData(UE || []);
        setTabLoader(false);

        // Automatically select the first event if available
        if (UE.length > 0) {
          setSelectedEvent(UE[0]);
          setStartIndex(0); // Ensure the start index is reset
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setTabLoader(false);
      });
  };

  useEffect(() => {
    const fetchRoleInfo = async () => {
      const roleUrl = `${CONFIG_VARS.gatwayApi}/role`;
      // const roleUrl=`https://gatewayapi.infinitylearn.com/api/v1/role`
      try {
        const response = await axios.get(roleUrl, {
          headers: {
            accept: "application/json",
            "product-id": "1000",
            "tenant-id": "1",
            "Content-Type": "application/json",
            platform: "web",
          },
        });
        if (response.status === 201) {
          setRoleInfo(response.data);
          return response.data;
        } else {
          console.error(
            "Failed to fetch role information. Response:",
            response
          );
          throw new Error("Failed to fetch role information");
        }
      } catch (error) {
        throw error;
      }
    };

    fetchRoleInfo();
  }, []);
  const fetchOnboardedStudents = () => {
    setDataLoader(true);
    if (schoolId === "") return;
    localStorage.setItem("SchoolID", schoolId);
    fetch(
      `${CONFIG_VARS.gatwayApi}/user/get-all-student-by-school?api_secret_key=${CONFIG_VARS.gatwayApikey}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "product-id": "100",
          "tenant-id": "99999",
        },
        body: JSON.stringify([schoolId]),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const students_data = data.hits.hits.map((item) => item._source);
        const transformedData = students_data.map((student) => {
          const exams = student.exams || [];
          const scoreAttempted = exams.includes(7);

          const registeredOn = formatDateTime(student.created_at || "-");
          const lastLogin = formatDateTime(student.last_active_at || "-");

          return {
            Name: `${student.first_name} ${student.last_name || ""}`,
            phone: scoreAttempted
              ? `XXXXXX${student.phone.slice(6)}`
              : student.phone || "-",
            Grade: student.grade_id || "-",
            "Login ID": student.admission_id || "-",
            "reg source": student.registration_source || "-",
            "Registered On": registeredOn,
            Onboarded: student.is_active,
            "Last Login": lastLogin,
          };
        });
        setDataLoader(false);
        setStudents(transformedData);
        fetchEvents();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // const getFirstEnabledEventIndex = (events) => {
  //   // Find the index of the first event with status "enabled"
  //   const index = events.findIndex((event) => event.status === "enabled");

  //   return index;
  // };

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const handleUpload = () => {
    if (selectedEvent?.canUpload || selectedTab === "Onboarded Students") {
      setUploadPopup(true);
    } else {
      alert("Upload is disabled for this event.");
    }
  };
  const handleCloseModal = () => {
    setUploadPopup(false);
  };

  const handleRightClick = () => {
    if (startIndex + 3 < eventData.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const handleLeftClick = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setCurrPage(1);
    setLimit(10);
  };

  const handleChangeTab = () => {
    fetchEvents();
    setStartIndex(0);
    handleTabClick("Events");
  };

  const enrichEventsWithStatus = (events) => {
    const referenceDate = new Date();
    referenceDate.setDate(referenceDate.getDate() - 21);

    const getStatus = (startDate) => {
      const eventDate = new Date(startDate);
      return eventDate < referenceDate ? "disabled" : "enabled";
    };

    return events.map((event) => ({
      ...event,
      status: getStatus(event.start_date_time),
      canUpload: isEventUploadEnabled(event.start_date_time),
    }));
  };

  return (
    <>
      <Navbar roleInfo={roleInfo} />

      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ width: "80%" }}>
          <h3 className="dashboard-heading">Welcome to Insights Dashboard</h3>
          <div className="inputCont">
            <span>Enter School ID: </span>
            <input
              className="inputBox"
              value={schoolId}
              onChange={(e) => setSchoolId(e.target.value)}
            />
            <button
              className="data-btn filter-btn"
              onClick={fetchOnboardedStudents}
            >
              {!getDataLoader ? (
                "Get Data"
              ) : (
                <span>
                  <img style={{ width: "1.5rem" }} src={loader} />
                </span>
              )}
            </button>
            <div className="TabsCont">
              <button
                className={`tabs ${
                  selectedTab === "Events" ? "selectedTab" : ""
                }`}
                onClick={handleChangeTab}
              >
                Events
              </button>
              <button
                className={`tabs ${
                  selectedTab === "Onboarded Students" ? "selectedTab" : ""
                }`}
                onClick={() => handleTabClick("Onboarded Students")}
              >
                Onboarded Students
              </button>
            </div>

            <div className="eventBtnCont">
              {selectedTab === "Events" && (
                <>
                  {eventData
                    ?.slice(startIndex, startIndex + 3)
                    .map((item, i) => (
                      <button
                        key={i}
                        className={`eventBtns ${
                          selectedEvent?.created_time +
                            selectedEvent?.start_date_time +
                            selectedEvent?.event ===
                          item?.created_time +
                            item?.start_date_time +
                            item?.event
                            ? "selectedEventBtn"
                            : ""
                        } ${
                          !isEventUploadEnabled(item?.start_date_time)
                            ? "disabled-event"
                            : "enabled"
                        }`}
                        onClick={() => handleEventClick(item)}
                      >
                        {eventLoader[item?.event] ? (
                          <img
                            style={{ width: "1.5rem" }}
                            src={loader}
                            alt="loading"
                          />
                        ) : (
                          item?.event
                        )}
                      </button>
                    ))}
                  {startIndex > 0 && (
                    <img src={left} onClick={handleLeftClick} />
                  )}
                  {startIndex + 3 < eventData.length && (
                    <img src={right} onClick={handleRightClick} />
                  )}
                </>
              )}
              {schoolId && (
                <div
                  style={{ display: "flex", marginLeft: "auto", gap: "15px" }}
                >
                  <div className="bulk-type-toggle-box">
                    <button
                      className={uploadType === "Register" ? "_active" : ""}
                      onClick={() => setUploadType("Register")}
                    >
                      Register
                    </button>
                    <button
                      className={uploadType === "Update" ? "_active" : ""}
                      onClick={() => setUploadType("Update")}
                    >
                      Update
                    </button>
                  </div>
                  {selectedTab === "Events" && (
                    <button
                      className="data-btn filter-btn"
                      disabled={uploading || eventData.length === 0}
                      onClick={handleUpload}
                    >
                      Upload
                    </button>
                  )}
                  {selectedTab !== "Events" && (
                    <button
                      className="data-btn filter-btn"
                      disabled={uploading}
                      onClick={handleUpload}
                    >
                      Upload
                    </button>
                  )}
                </div>
              )}
            </div>

            <p>
              Total Students:{" "}
              {selectedTab === "Onboarded Students" ? (
                tabLoader ? (
                  <span>
                    <img style={{ width: "1.5rem" }} src={loader} />
                  </span>
                ) : (
                  students.length
                )
              ) : tabLoader ? (
                <span>
                  <img style={{ width: "1.5rem" }} src={loader} />
                </span>
              ) : (
                totalCount
              )}
            </p>
          </div>
          <section className="section">
            {selectedTab === "Onboarded Students" ? (
              <div style={{ width: "100%" }}>
                <div
                  className="table-responsive text-nowrap mt-3"
                  style={{ maxHeight: "80vh", overflowY: "auto" }}
                >
                  {students.length > 0 ? (
                    <table className="table" id="table-to-xls">
                      <thead>
                        <tr className="text-nowrap">
                          {tableHeaders.map((header, index) => (
                            <th key={index} className="table-header">
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {students.map((row, index) => (
                          <tr key={index}>
                            {tableHeaders.map((header, idx) => (
                              <td key={idx}>{row[header]}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="NoDataCont">
                      <p>No data available. Please upload..!</p>
                      <button
                        className="data-btn filter-btn"
                        onClick={handleUpload}
                      >
                        Upload
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                >
                  <CircularProgress color="secondary" />
                </Backdrop>
                {studentsByEvent?.length === 0 ? (
                  <div className="NoDataCont">
                    <p>No data available. Please upload to continue.</p>
                    {schoolId && (
                      <button
                        className="data-btn filter-btn"
                        disabled={uploading || eventData.length === 0}
                        onClick={() => handleUpload("upload")}
                      >
                        Upload Data
                      </button>
                    )}
                  </div>
                ) : (
                  <EventTable
                    navigationId={2}
                    loading={false}
                    data={studentsByEvent}
                    pageTitle="Events"
                    currPage={currPage}
                    limit={limit}
                    setCurrPage={setCurrPage}
                    setTotalPages={setTotalPages}
                    totalPages={totalPages}
                    setLimit={setLimit}
                    totalCount={totalCount}
                  />
                )}
              </>
            )}
          </section>
        </div>
        {uploadPopup && (
          <UploadPopUp
            selectedEvent={selectedEvent}
            schoolId={schoolId}
            handleCloseModal={handleCloseModal}
            setUploading={setUploading}
            uploadType={uploadType}
            selectedTab={selectedTab}
          />
        )}
      </div>
    </>
  );
};

export default Events;
