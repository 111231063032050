import React, { useState, useEffect } from "react";
import axios from "axios";

import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { isAuthenticated } from "../../../../utils/auth";
import { ThreeDots } from "react-loader-spinner";
import TextField from "@mui/material/TextField";
import { CONFIG_VARS, moduleConfig } from "../../../constants/configuration";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton } from "@mui/material";

const LoginForm = () => {
  const [fields, setFields] = useState(moduleConfig.loginusers);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [validationErrors, setValidationErrors] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleChange = (e, schema) => {
    const { name, value } = e.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
    setError("");
    schema
      .validate(value)
      .then(() =>
        setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }))
      )
      .catch((error) =>
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message,
        }))
      );
  };

  const hasValidationErrors = () => {
    for (const fieldName in validationErrors) {
      if (
        validationErrors.hasOwnProperty(fieldName) &&
        validationErrors[fieldName] !== ""
      ) {
        return true;
      }
    }
    return false;
  };

  const buttonDisabled = () => {
    let flag = false;
    for (let i = 0; i < fields.length; i++) {
      const fieldName = fields[i].name;
      const isMandatory = fields[i].mandatory;
      if (isMandatory && !formData[fieldName]) {
        flag = true; // Set flag to true if any mandatory field is not filled
        break; // If any mandatory field is not filled, disable the button
      }
      if (isMandatory && hasValidationErrors()) {
        flag = true;
        break;
      }
    }
    return flag;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const loginUrl = `${CONFIG_VARS.gatwayApi}/user/agents/login`;
    const headers = {
      accept: "application/json",
      "product-id": "1000",
      "tenant-id": "99999",
      "Content-Type": "application/json",
      platform: "web",
    };

    const requestBody = {
      admission_number: formData.admission_number,
      password: formData.password,
      isd_code: "+91",
    };

    try {
      const response = await axios.post(loginUrl, requestBody, {
        headers: headers,
      });
      const isSuperAdmin = CONFIG_VARS.roles["Inmboius Super Admin"].some(
        (role) => response.data.roles.includes(role)
      );
      const isRep = CONFIG_VARS.roles["Inmobius Rep"].some((role) =>
        response.data.roles.includes(role)
      );

      console.log(response.data.roles);

      if (isSuperAdmin) {
        if (response.status === 200) {
          // Store the access token in local storage
          localStorage.setItem("accessToken", response.data.accessToken);
          localStorage.setItem("user_data", JSON.stringify(response.data));

          history.push(ROUTES.STUDENT);
        } else {
          setIsLoading(false);
        }
      } else if (isRep) {
        if (response.status === 200) {
          // Store the access token in local storage
          localStorage.setItem("accessToken", response?.data?.accessToken);
          localStorage.setItem("user_data", JSON.stringify(response.data));

          history.push(ROUTES.EVENT);
        } else {
          setIsLoading(false);
        }
      } else {
        setError("Invalid username or password");
        setIsLoading(false);
      }
    } catch (error) {
      if (error?.response?.data?.detail) {
        setError(error?.response?.data?.detail);
        setIsLoading(false);
      } else {
        setError("Oops, something went wrong");
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated()) {
      history.push(ROUTES.EXPLORE_TABLE);
    }
  }, [history]);

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-8 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a
                    href="index.html"
                    className="logo d-flex align-items-center w-auto"
                  >
                    <span className="d-none d-lg-block">
                      InMobius Insights Dashboard
                    </span>
                  </a>
                </div>
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Login to Your Account
                      </h5>
                      <p className="text-center small">
                        Enter your Login Id & Password to login
                      </p>
                    </div>
                    <form className="login-form">
                      {fields.map((field) => {
                        return (
                          <div className="col-md-12 mt-3" key={field.name}>
                            <TextField
                              id={`floating${field.name}`}
                              name={field.name}
                              value={formData[field.name] || ""}
                              type={
                                field.type === "password"
                                  ? showPassword
                                    ? "text"
                                    : "password"
                                  : field.type
                              }
                              fullWidth
                              onChange={(e) => handleChange(e, field.schema)}
                              label={field.label}
                              variant="outlined"
                              error={!!validationErrors[field.name]}
                              helperText={validationErrors[field.name]}
                              InputProps={
                                field.type === "password"
                                  ? {
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={
                                              handleTogglePasswordVisibility
                                            }
                                          >
                                            {showPassword ? (
                                              <VisibilityIcon />
                                            ) : (
                                              <VisibilityOffIcon />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }
                                  : null
                              }
                            />
                          </div>
                        );
                      })}
                      <div className="col-12">
                        {error === "" ? null : (
                          <p className="py-2">
                            <span className="text-danger">{error}</span>
                          </p>
                        )}
                        <button
                          className={`btn btn-primary w-100 login-btn my-3 ${
                            isLoading ? "disabled" : ""
                          }`}
                          type="submit"
                          onClick={handleLogin}
                          disabled={buttonDisabled()}
                        >
                          {isLoading ? (
                            <ThreeDots color="#ffffff" height={40} width={40} />
                          ) : (
                            "Login"
                          )}
                        </button>
                      </div>
                      <div className="col-12">
                        <p className="small mb-0">
                          <Link to={ROUTES.RESET_PASSWORD}>
                            Forgot Password?
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
export default LoginForm;
