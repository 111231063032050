import React from 'react';

const ParticipationItem = ({ icon, title, subtitle, progress }) => {
  return (
    <li className="d-flex mb-4 pb-1">
      <div className="avatar flex-shrink-0 me-3">
        <span className="avatar-initial rounded bg-label-primary">
          <i className={icon} />
        </span>
      </div>
      <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
        <div className="me-2">
          <h6 className="mb-0">{title}</h6>
          <small className="text-muted">{subtitle}</small>
        </div>
        <div className="user-progress">
          <small className="fw-semibold">{progress}</small>
        </div>
      </div>
    </li>
  );
};

export default ParticipationItem;
