import React from "react";
import Papa from "papaparse";
import uploadimg from "../../../assets/img/Svgs/uploadfile.svg";

const UploadStep = ({ handleFileChange, setFile }) => {
    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            setFile(file);
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    handleFileChange(results.data);
                },
            });
        }
    };

    return (
        <div className="uploadModal-header1">
            <div>
                <input type="file" id="fileInput" accept=".csv" onChange={handleFileUpload} />
                <label className="uploadInput" htmlFor="fileInput">
                    UPLOAD FILE
                    <img style={{ width: "1rem" }} src={uploadimg} alt="upload" />
                </label>
            </div>
            <div>
                <p>
                    File size must be less than 20MB
                    <br /> Supported format for upload- .csv
                </p>
            </div>
        </div>
    );
};

export default UploadStep;
