import React from "react";
import { Sidebar } from "./components/Sidebar/Sidebar";
import { WhiteCard } from "./components/Whitecard/WhiteCard";
import { SchoolOnboardCard } from "./components/Cards/SchoolOnboard/SchoolOnboardCard";
import { StudentOnboardCard } from "./components/Cards/StudentOnboard/StudentOnboardCard";
import Navbar from "./components/Navbar/Navbar";
import { ExamScheduleCard } from "./components/Cards/ExamsSchedule/ExamScheduleCard";
import { LiveTrafficCard } from "./components/Cards/LiveTraffic/LiveTrafficCard";
import ParticipationWidget from "./components/AuthWidgets/ParticipationReportWidget/ParticipationWidget";
import { StudentRegisterCard } from "./components/Cards/StudentsRegistered/StudentRegisteredCard";
import { StudentAttemptedCard } from "./components/Cards/StudentsAttempted/StudentAttemptedCard";
import { StudentLoggedInCard } from "./components/Cards/StudentLoggedIn/StudentLoggedInCard";

const AuthPage = () => {
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <Sidebar />
        <div className="layout-page">
          <Navbar />
          <div className="content-wrapper p-4">
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="row">
                <div className="col-lg-12 order-0">
                  <div className="row justify-content-between">
                    <WhiteCard />
                    <StudentRegisterCard />
                    <StudentLoggedInCard />
                  </div>
                </div>
                <div className="col-lg-12 mb-4 order-1">
                  <div className="row justify-content-between">
                    <SchoolOnboardCard />
                    <ExamScheduleCard />
                    <LiveTrafficCard />
                    <StudentOnboardCard />
                    <StudentAttemptedCard />
                  </div>
                </div>
              </div>
              <ParticipationWidget />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
