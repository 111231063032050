import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";

import { ROUTES } from "../pages/constants/routes";
import LoginForm from "../pages/components/Tables/Explore/LoginForm";
import ResetPassword from "../pages/components/validations/ResetPassword";
import InsightDashboard from "../pages/components/InsightDashboard/InsightDashboard";
import Event from "../pages/components/InsightDashboard/Events";
import SessionTimeoutWrapper from "../utils/SessionTimeout";

import AuthPage from "../pages/AuthPage";
import StudentOnboardingTable from "../pages/components/Tables/StudentOnboardingTable/StudentOnboardTable";
import SchoolOnboardingTable from "../pages/components/Tables/SchoolOnboardingTable/SchoolOnboardTable";
import ParticipationInsightsTable from "../pages/components/Tables/ParticipationInsightsTable/ParticipationInsightsTable";
import Footer from "../pages/components/validations/Footer";
// export const MainRoutes = () => {
//   useEffect(() => {
//     // Any code you want to run on component mount
//   }, []);

const AuthenticatedRoutes = () => {
  const location = useLocation();

  const shouldShowFooter = () => {
    const nonFooterRoutes = [ROUTES.LOGIN, ROUTES.RESET_PASSWORD];
    return !nonFooterRoutes.includes(location.pathname);
  };

  return (
    <>
      <Switch>
        <Route path={ROUTES.EVENT} component={Event} />
        <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
        <Route path={ROUTES.STUDENT} component={InsightDashboard} />
        <Redirect to={ROUTES.EVENT} />
        <Route exact path={ROUTES.HOME} component={AuthPage} />
        <Route
          path={ROUTES.SCHOOL_ONBORADING_TABLE}
          component={SchoolOnboardingTable}
        />
        <Route
          path={ROUTES.STUDENT_ONBORADING_TABLE}
          component={StudentOnboardingTable}
        />
        <Route path={ROUTES.EVENT} component={Event} />
        <Route
          path={ROUTES.PARTICIPATION_TABLE}
          component={ParticipationInsightsTable}
        />
        <Redirect to={ROUTES.EXPLORE_TABLE} />
      </Switch>
      {shouldShowFooter() && <Footer />}
    </>
  );
};

const MainRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path={ROUTES.LOGIN} component={LoginForm} />
        <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
        {/* Add other routes as needed */}
        <Route>
          <SessionTimeoutWrapper>
            <AuthenticatedRoutes />
          </SessionTimeoutWrapper>
        </Route>
      </Switch>
    </Router>
  );
};

export default MainRouter;
