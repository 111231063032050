import React, { useState, useEffect } from "react";
import "./EditStep.css";

const EditStep = ({ csvData, validationErrors, handleUpdateCsvData }) => {
    const [editedData, setEditedData] = useState([]);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        setEditedData(csvData); // Initialize edited data with CSV data
        setErrors(validationErrors); // Initialize errors if any
    }, [csvData, validationErrors]);

    // Calculate total number of error rows
    const totalErrorRows = errors.filter((error) => error && error.errors.length > 0).length;

    return (
        <div className="editcontianer">
            <h3>Total Error Rows: {totalErrorRows}</h3>
            <table>
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone</th>
                        <th>Grade</th>
                        <th>School ID</th>
                    </tr>
                </thead>
                <tbody>
                    {errors.map((error, index) => (
                        <tr className="trcontainer" key={index}>
                            <td className={error.errors.includes("Invalid first name") ? "errorMessage" : ""}>
                                {error?.data?.first_name}
                            </td>
                            <td className={error.errors.includes("Invalid last name") ? "errorMessage" : ""}>
                                {error?.data?.last_name}
                            </td>
                            <td className={error.errors.includes("Invalid phone number") ? "errorMessage" : ""}>
                                {error?.data?.phone}
                            </td>
                            <td className={error.errors.includes("Invalid Grade") ? "errorMessage" : ""}>
                                {error?.data?.grade}
                            </td>
                            <td className={error.errors.includes("Invalid school ID") ? "errorMessage" : ""}>
                                {error?.data?.school_id}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default EditStep;
