import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { ROUTES } from "../constants/routes";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Sidebar } from "./Sidebar/Sidebar";
const Navbar = ({ roleInfo }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState({});
  const profileDropdownOpen = Boolean(anchorEl);
  const [RoleName, setRoleName] = useState("");
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user_data"));

    setUserData(userData);
    if (
      userData &&
      roleInfo &&
      roleInfo.roles &&
      userData.roles &&
      roleInfo.roles.length > 0
    ) {
      const roleName = roleInfo.roles
        .filter((role) => role.role_id === userData.roles[0])
        .map((filteredRole) => filteredRole.role_name);

      setRoleName(roleName[0]);
    }
  }, [roleInfo]);

  const onLogout = () => {
    localStorage.removeItem("sessionStart");
    localStorage.removeItem("user_data");
    localStorage.removeItem("accessToken");
    history.push(ROUTES.LOGIN);
  };

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        {/* <Sidebar /> */}
        <div className="d-flex align-items-center justify-content-between">
          <img src="assets/img/logo.png" alt="" />
          <span
            style={{ fontSize: "20px", fontWeight: "600", color: "#012970" }}
          >
            Login Id: {userData.admission_number}
          </span>
          <span
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "#012970",
              marginLeft: "10px",
            }}
          >
            Role: {RoleName || "NA"}
          </span>
        </div>
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li
              className={`nav-item custom-dropdown pe-3 ${
                profileDropdownOpen ? "open" : ""
              }`}
            >
              <div
                className="nav-link nav-profile d-flex align-items-center pe-0"
                onClick={handleClick}
              >
                {userData?.firstName ? (
                  <Avatar
                    sx={{
                      bgcolor: "#012970",
                      fontSize: "16px",
                    }}
                  >{`${userData?.firstName[0]}${userData?.lastName[0]}`}</Avatar>
                ) : null}
                <span className="d-none d-md-block profile-name ps-2">{`${userData?.firstName} ${userData?.lastName}`}</span>
                <i
                  className={`bi bi-chevron-${
                    !profileDropdownOpen ? "down" : "up"
                  } m-2`}
                ></i>
              </div>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={profileDropdownOpen}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {/* <MenuItem onClick={handleClose} sx={{ gap: "10px" }}>
                  <ListItemIcon>
                    <Avatar fontSize="small" />
                  </ListItemIcon>
                  My Profile
                </MenuItem>
                <Divider /> */}
                {/* <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Account Settings
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <HelpOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  Need help?
                </MenuItem> */}
                <MenuItem onClick={onLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
