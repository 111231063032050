import React, { useContext, useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";

import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import Select from "@mui/material/Select";
import { itemsPerPageOptions } from "../../constants/index";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";

import { TableRow } from "./TableRow";
import { TableConfig } from "./TableConfig";
import { CONFIG_VARS } from "../config/globleConfig";
import { moduleConfig } from "../config";

const Table = (props) => {
  const {
    data,
    loading,
    pageTitle,
    navigationId,
    setCurrPage,
    setLimit,
    currPage,
    totalPages,
    limit,
    setProductIds,
    setData,
    sort,
    setSort,
    totalCount,
    setTotalPages,
    setLoading,
  } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [prevPage, setPrevPage] = useState(1);
  const [privLimmit, setPrivLimmit] = useState(5);
  const [selectedProduct, setSelectedProduct] = useState();
  const [filterMdlOpen, setFilterMdlOpen] = useState(false);
  const [filtersList, setFiltersList] = useState([
    {
      id: 0,
      column: moduleConfig[pageTitle.toLowerCase()]?.searchableItems[0]?.value,
      value: null,
    },
  ]);
  const [singleFilter, setSingleFilter] = useState({
    filterTerm: null,
    filterType:
      moduleConfig[pageTitle.toLowerCase()]?.searchableItems[0]?.value,
  });

  const { products, handleOpenSnackbar, access } =
    useContext(CommonApiDataContext);

  useEffect(() => {
    const inmobiusProd = getProds();
    setSelectedProduct((inmobiusProd && inmobiusProd[0]?.value) || "");
    setTotalPages(Math.ceil(totalCount / limit));
  }, [products, Math.ceil(totalCount / limit)]);

  /*@TODO: need remove in final stage */
  const getProds = () => {
    if (access && access.includes("inmobius")) {
      return products.filter((p) => p.value === CONFIG_VARS.inmobiusProdId);
    } else {
      return products;
    }
  };

  const handleChange = (value) => {
    setProductIds && setProductIds(`${value}`);
    setSelectedProduct(value);
    setCurrPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrPage(1);
  };

  const pageChangeHandler = (event, value) => {
    setCurrPage(value);
    if (value !== prevPage) {
      setLoading(true);
    }
    setPrevPage(value);
  };

  const limitChangeHandler = (e) => {
    setCurrPage(1);
    setLimit(parseInt(e.target.value));
    if (e.target.value !== privLimmit) {
      setLoading(true);
    }
    setPrivLimmit(e.target.value);
  };

  const ToggleSort = () => {
    sort && sort === "asc" ? setSort("desc") : setSort("asc");
    setCurrPage(1);
  };

  return (
    <div className="table-card">
      {loading ? (
        <div className="loader-container">
          <ThreeDots type="Oval" color="#4154f1" height={80} width={80} />
        </div>
      ) : (
        <div>
          <div className="table-head-cont">
            <p>
              {" "}
              Total Students : <span>{totalCount}</span>
            </p>
            {data.length !== 0 && (
              <div className="table-pagn-itemspp-box">
                {/* <p className="select-label">Items per Page:</p>
                            <select className="select" value={limit} onChange={(e) => limitChangeHandler(e)}>
                                {itemsPerPageOptions.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select> */}

                <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Items per Page
                  </InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: 120, width: 100 } },
                    }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={limit}
                    label="Items per Page"
                    onChange={(e) => limitChangeHandler(e)}
                  >
                    {itemsPerPageOptions.map((option) => (
                      <MenuItem key={option?.value} value={option?.value}>
                        {option?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Pagination
                  variant="outlined"
                  color="primary"
                  count={totalPages}
                  page={currPage}
                  onChange={pageChangeHandler}
                />
              </div>
            )}
          </div>
          {/* ==================== Table starts ==================== */}
          {TableConfig.tables[navigationId].items?.length > 0 && (
            <div className="table_in">
              <table>
                <thead>
                  <tr>
                    {TableConfig.tables[navigationId].items.map((column) => {
                      const shouldDisplay =
                        column.displayTable &&
                        (column.type !== "toggle" || access !== "other");
                      return (
                        shouldDisplay && (
                          <th key={column.id}>
                            {column.name}
                            {column?.sort && (
                              <SwapVertIcon
                                className="sort-icon"
                                onClick={ToggleSort}
                              />
                            )}
                          </th>
                        )
                      );
                    })}
                    {/* <th>More</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 &&
                    data.map((item) => (
                      <TableRow
                        key={
                          item[TableConfig.tables[navigationId].items[0].crn_id]
                        }
                        id={item.crn_id}
                        rowConfig={TableConfig.tables[navigationId].items}
                        item={item}
                        // onToggle={onToggle}
                        // EditForm={EditForm}
                        // updateItem={updateItem}
                        pageTitle={pageTitle}
                        handleOpenSnackbar={handleOpenSnackbar}
                        dta={data}
                        setData={setData}
                        access={access}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          )}
          {/* ==================== Table ends ==================== */}

          {/* ==================== empty table view starts ==================== */}
          {data?.length === 0 && (
            <div className="table-empty-view">
              <h2>Oops! Data Not Found.</h2>
            </div>
          )}
          {/* ==================== empty table view ends ==================== */}

          {/* ==================== Table footer starts ==================== */}

          {/* ==================== Table footer ends ==================== */}
        </div>
      )}
    </div>
  );
};

export default Table;
