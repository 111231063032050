import React from "react";

const FailedRecordsStep = ({ uploadStatusData }) => {
  const failedRecords = uploadStatusData?.detail || [];

  return (
    <div className="editcontianer">
      <h2>Failed Records</h2>
      <table>
        <thead>
          <tr>
            <th>Phone</th>
            <th>Failure Reason</th>
          </tr>
        </thead>
        <tbody>
          {failedRecords.map((record, index) => (
            <tr key={index}>
              <td>{record.phone}</td>
              <td>{record.failure_reason}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FailedRecordsStep;
