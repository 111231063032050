import React from "react";
import GlobalDropdown from "../Dropdowns/GlobalDropdown";

const Widget = ({ title, content, dropdownItems, children }) => {
  return (
    <div className="col-md-12 col-lg-12 col-xl-12 order-0 mb-4">
      <div className="card h-100">
        <div className="card-header d-flex align-items-center justify-content-between pb-0">
          <div className="card-title mb-0">
            <h5 className="m-0 me-2">{title}</h5>
            {content && <small className="text-muted">{content}</small>}
          </div>
          {dropdownItems && <GlobalDropdown buttons={dropdownItems} />}
        </div>
        <div className="card-body">{children}</div>
      </div>
    </div>
  );
};

export default Widget;
