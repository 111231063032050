import React from 'react';

const GlobalDropdown = ({ title, content, buttons, className }) => {
  return (
    <div className={`dropdown ${className}`}>
      <button
        className="btn p-0"
        type="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="bx bx-dots-vertical-rounded"></i>
      </button>
      <div className="dropdown-menu dropdown-menu-end">
        <h6 className="dropdown-header">{title}</h6>
        {content && <div className="dropdown-content">{content}</div>}
        <div className="dropdown-buttons">
          {buttons &&
            buttons.map((button, index) => (
              <a
                key={index}
                className={`dropdown-item ${button.buttonClassName}`}
                href={button.link}
                onClick={button.onClick}
              >
                {button.text}
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

export default GlobalDropdown;
