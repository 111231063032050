import React, { useEffect, useState } from "react";

const ValidationStep = ({ csvData, handleValidationResult }) => {
  const [isValidating, setIsValidating] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const validateCsvData = (data) => {
    const errors = [];
    const validData = [];
    const schoolid = localStorage.getItem("SchoolID");
    console.log(schoolid);

    data.forEach((row, index) => {
      const { first_name, last_name, phone, school_id, grade } = row;
      const errorMessages = [];

      if (!first_name || !/^[A-Za-z\s]+$/.test(first_name)) {
        errorMessages.push("Invalid first name");
      }

      if (last_name && !/^[A-Za-z\s]+$/.test(last_name)) {
        errorMessages.push("Invalid last name");
      }

      if (!phone || !/^\d{10}$/.test(phone)) {
        errorMessages.push("Invalid phone number");
      }
      const schoolIdPattern = /^[a-zA-Z]+\d+$/;
      if (!school_id) {
        errorMessages.push("Invalid school ID");
      } else if (!schoolIdPattern.test(school_id)) {
        errorMessages.push("Invalid school ID");
      } else if (school_id !== schoolid) {
        errorMessages.push("Invalid school ID");
      }

      const parsedGrade = Number(grade);
      if (isNaN(parsedGrade) || parsedGrade < 1 || parsedGrade > 21) {
        errorMessages.push("Invalid Grade");
      }

      if (errorMessages.length > 0) {
        errors.push({ index, errors: errorMessages, data: row });
      } else {
        validData.push(row);
      }
    });

    return { validData, errors };
  };

  useEffect(() => {
    const { validData, errors } = validateCsvData(csvData);

    // Delay the transition to the next step by at least 5 seconds
    const timer = setTimeout(() => {
      setIsValidating(false);
      setIsValid(errors.length === 0);
      setValidationErrors(errors);
      handleValidationResult(validData, errors);
    }, 5000);

    // Cleanup timer if the component is unmounted
    return () => clearTimeout(timer);
  }, [csvData, handleValidationResult]);

  return (
    <div>
      <h2>Validating CSV Data...</h2>
      {isValidating && <p>Scanning in progress...</p>}
      {!isValidating && isValid && <p>File is clean to upload.</p>}
      {!isValidating && !isValid && (
        <p>File has errors. Click View to Check the errors.</p>
      )}
    </div>
  );
};

export default ValidationStep;
