import React from "react";

const SearchInput = () => (
  <div className="navbar-nav align-items-center">
    <div className="nav-item d-flex align-items-center">
      <i className="bx bx-search fs-4 lh-0" />
      <input
        type="text"
        className="form-control border-0 shadow-none"
        placeholder="Search..."
        aria-label="Search..."
      />
    </div>
  </div>
);

export default SearchInput;
