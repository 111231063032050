import React from 'react';

const NavbarToggle = () => (
  <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
    <a className="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
      <i className="bx bx-menu bx-sm" />
    </a>
  </div>
);

export default NavbarToggle;
