import React from "react";
import { Sidebar } from "../../Sidebar/Sidebar";
import Dropdown from '../Dropdown';
import Table from '../Table';
import { dropdowns } from './DropdownConfig';
import { tableHeaders, tableData, title } from './componentsConfig';
import Navbar from "../../Navbar/Navbar";


const StudentOnboardingTable = () => {
  return (
    <div className="layout-wrapper layout-content-navbar ">
      <div className="layout-container">
        <Sidebar />
        <div className="layout-page">
          <Navbar/>
          <div className="content-wrapper">
            {/* Content */}
            <div className="container">
              <h4>Welcome to Insights Dashboard</h4>
              {dropdowns.map((dropdown, index) => (
                <Dropdown
                  key={index}
                  title={dropdown.title}
                  options={dropdown.options}
                />
              ))}
              <Table headers={tableHeaders} data={tableData} title={title} />{" "}
              {/* Updated component */}
            </div>
          </div>
        </div>
        <div className="content-backdrop fade" />
      </div>
      <div className="layout-overlay layout-menu-toggle" />
    </div>
  );
};

export default StudentOnboardingTable;
