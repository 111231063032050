export const ROUTES = Object.freeze({
  LOGIN: "/login",
  HOME: "/auth",
  RESET_PASSWORD: "/reset-password",
  SCHOOL_ONBORADING_TABLE: "/SchoolTable",
  STUDENT_ONBORADING_TABLE: "/StudentTable",
  PARTICIPATION_TABLE: "/ParticipationTable",
  EXPLORE_TABLE: "/",
  EVENT: "/event",
  STUDENT: "/student",
  UPLOAD_DATA: "/UploadData",
});
