export const cardConfig = [
  {
    id: 1,
    type: 'SchoolOnboard',
    title: 'Schools Onboarded',
    value: '12,628',
    growth: '+72.80%',
  },
  {
    id: 2,
    type: 'StudentOnboard',
    title: 'Students Onboarded',
    value: '4,679',
    growth: '+28.42%',
  },
  {
    id: 3,
    type: 'ExamsScheduled',
    title: 'Exams Scheduled',
    value: '2,456',
    growth: -14.82,
  },
  {
    id: 4,
    type: 'LiveTraffic',
    title: 'Live Traffic',
    value: '14,857',
    growth: 28.14,
  },
  {
    id: 5,
    type: 'StudentLoggedIn',
    title: 'Student LoggedIn',
    value: '14,857',
    growth: 28.14,
  },
  {
    id: 6,
    type: 'StudentAttempted',
    title: 'Student Attempted',
    value: '14,857',
    growth: 28.14,
  },
  {
    id: 7,
    type: 'StudentRegistered',
    title: 'Student Registered',
    value: '14,857',
    growth: 28.14,
  }
  // Add more cards here...
];
