import React from "react";
import { Card } from "../Cards";
import { cardConfig } from "../config"; // Import the card configuration
import ccprimary from "../../../../assets/img/icons/unicons/cc-primary.png";

export const LiveTrafficCard = () => {
  const cardData = cardConfig.find((card) => card.type === "LiveTraffic");
  const handleViewMoreClick = () => {
    // Handle View More click
  };

  const handleDeleteClick = () => {
    // Handle Delete click
  };

  const dropdownButtons = [
    {
      text: "View More",
      link: "javascript:void(0);",
      onClick: handleViewMoreClick,
      buttonClassName: "",
    },
    {
      text: "Delete",
      link: "javascript:void(0);",
      onClick: handleDeleteClick,
      buttonClassName: "text-danger",
    },
  ];

  const cardDropdownConfig = {
    buttons: dropdownButtons,
    className: "my-dropdown",
  };

  return (
    <div className="col-2">
      <div>
        <Card
          title={cardData.title}
          iconSrc={ccprimary}
          value={cardData.value}
          cardDropdownConfig={cardDropdownConfig}
        />
      </div>
    </div>
  );
};
