import React from "react";
import moment from "moment";
import { Avatar } from "@mui/material";

export const TableRow = (props) => {
  const { rowConfig, item } = props;

  // const getFormattedDateTime = (date) => {
  //   const localTime = moment.utc(date).local().format("YYYY-MM-DD HH:mm:ss");
  //   return localTime;
  // };
  const getFormattedDateTime = (date) => {
    if (!date) return "--";
    const parsedDate = moment(date);
    const formattedDate = parsedDate.format("YYYY-MM-DD HH:mm");
    return formattedDate;
  };
  const getNestedValue = (obj, path) => {
    const keys = path.split(".");
    let value = obj;
    for (let key of keys) {
      value = value?.[key];
    }
    return value;
  };
  const maskPhoneNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.length < 10) return phoneNumber;
    return "* * * * * * * " + phoneNumber.slice(-3);
  };
  return (
    <tr key={item[rowConfig[0].id]}>
      {rowConfig.map((i) => {
        const value = getNestedValue(item, i.id);
        const isAttempted = getNestedValue(item, "exam_attempted");
        switch (i.type) {
          case "default":
            return (
              i.displayTable && (
                <td key={`table_data_${rowConfig.indexOf(i)}_${item[i.id]}`}>
                  {getNestedValue(item, i.id) || "--"}
                </td>
              )
            );
          case "phone":
            return (
              i.displayTable && (
                <td key={`table_data_${rowConfig.indexOf(i)}_${item[i.id]}`}>
                  {isAttempted ? maskPhoneNumber(value) : value}
                </td>
              )
            );
          case "time":
            const formatedDate = getFormattedDateTime(
              getNestedValue(item, i.id)
            );
            return (
              i.displayTable && (
                <td
                  key={`table_data_${rowConfig.indexOf(i)}_${
                    item[i.id]
                  }xxxxxxxx`}
                >
                  {formatedDate || "--"}
                </td>
              )
            );
          case "image":
            return (
              i.displayTable && (
                <td
                  key={`table_data_${rowConfig.indexOf(i)}_${
                    item[i.id]
                  }xxxxxxxx`}
                >
                  {item[i.id] ? (
                    <Avatar
                      sx={{ maxWidth: "30px", maxHeight: "30px" }}
                      alt="Remy Sharp"
                      src={item[i.id]}
                    />
                  ) : (
                    "---"
                  )}
                </td>
              )
            );
          case "boolean":
            return (
              i.displayTable && (
                <td key={`table_data_${rowConfig.indexOf(i)}_${item[i.id]}`}>
                  {getNestedValue(item, i.id) ? "Yes" : "No"}
                </td>
              )
            );
          default:
            break;
        }
        return null;
      })}
    </tr>
  );
};
