import {
    codeSchema,
    multiSelectSchema,
    nameSchema,
    phoneSchema,
    passwordSchema,
    emailSchema,
    noSpecCharSchema,
    productIdSchema,
    URLSchema,
    nameSpaceSchema,
    singleselectSchema,
} from "../../components/validations/validations";
// import { radioGroupDirectionStylesForRoles } from "../constants";
// import {
//     getBranchOptions,
//     getGrades,
//     getSchoolIds,
//     getTenantOptions,
//     createTenant,
//     updateTenant,
//     CreateSchool,
//     UpdateSchool,
//     CreateAgents,
//     UpdateAgent,
//     createGrades,
//     updateGrades,
//     CreateProduct,
//     UpdateProduct,
//     CreateRole,
//     UpdateRole,
//     CreateExam,
//     UpdateExam,
//     CreateProgram,
//     UpdateProgram,
// } from "../utils";

export const moduleConfig = {
    loginusers: [
        {
            name: "admission_number",
            type: "text",
            label: "Admission Id",
            schema: nameSchema,
            mandatory: true,
        },
        {
            name: "password",
            type: "password",
            label: "Password",
            schema: passwordSchema,
            mandatory: true,
        },
    ],

    tenants: {
        // actions: {
        //     create: createTenant,
        //     update: updateTenant,
        // },
        fields: [
            {
                name: "tenant_id",
                type: "text",
                label: "Tenant Id",
                mandatory: true,
                showInCreate: false,
                editable: false,
            },
            {
                name: "products",
                type: "searchmultiSelect",
                label: "Select Products",
                options: [],
                mandatory: true,
                showInCreate: true,
                schema: multiSelectSchema,
                editable: true,
            },
            {
                name: "tenant_name",
                mandatory: true,
                type: "text",
                label: "Tenant Name",
                schema: nameSpaceSchema,
                maxChar: 50,
                editable: true,
                showInCreate: true,
            },
            {
                name: "tenant_code",
                mandatory: true,
                type: "text",
                label: "Tenant Code",
                button: "Generate Code",
                refField: "tenant_name",
                schema: codeSchema,
                maxChar: 5,
                editable: true,
                showInCreate: true,
            },
            {
                name: "primary_contact_name",
                mandatory: true,
                type: "text",
                label: "Contact Name",
                schema: nameSpaceSchema,
                maxChar: 50,
                editable: true,
                showInCreate: true,
            },
            {
                name: "primary_contact_phone",
                mandatory: true,
                type: "phone",
                label: "Contact Phone",
                schema: phoneSchema,
                maxChar: 10,
                editable: true,
                showInCreate: true,
            },
            {
                name: "primary_contact_email",
                mandatory: true,
                type: "email",
                label: "Contact Email",
                schema: emailSchema,
                editable: true,
                showInCreate: true,
            },
            {
                name: "grades",
                mandatory: true,
                type: "searchmultiSelect",
                label: "Select Grades",
                options: [],
                schema: multiSelectSchema,
                editable: true,
                showInCreate: true,
            },
            // {
            //     name: "source",
            //     mandatory: false,
            //     type: "text",
            //     label: "Source",
            //     showInCreate: false,
            //     editable: true,
            // },
            {
                name: "source",
                type: "searchsingleSelect",
                label: "Select Source",
                schema: singleselectSchema,
                mandatory: true,
                options: [
                    { label: "Inmobius", value: "IM" },
                    { label: "Infinity Learn", value: "IL" },
                ],
                showInCreate: true,
                editable: false,
            },
            {
                name: "image",
                type: "image",
                label: "Image",
                editable: false,
                showInCreate: true,
            },
        ],
        searchableItems: [
            { label: "Tenant Id", value: "tenant_id" },
            { label: "Tenant Name", value: "name" },
            { label: "Tenant Phone", value: "phone" },
            { label: "Tenant Code", value: "tenant_code" },
        ],
    },

  

    agents: {
        // actions: {
        //     create: CreateAgents,
        //     update: UpdateAgent,
        // },
        fields: [
            {
                name: "products",
                type: "searchmultiSelect",
                label: "Select Products",
                options: [],
                showInCreate: true,
                schema: multiSelectSchema,
                mandatory: true,
                editable: true,
            },
            {
                name: "admission_number",
                type: "text",
                label: "Admission Number",
                showInCreate: true,
                schema: noSpecCharSchema,
                maxChar: 50,
                mandatory: true,
                editable: false,
                button: "Generate Code",
                refField: "tenant_name",
            },
            {
                name: "first_name",
                type: "text",
                label: "First Name",
                showInCreate: true,
                schema: nameSpaceSchema,
                maxChar: 50,
                mandatory: true,
                editable: true,
            },
            {
                name: "last_name",
                type: "text",
                label: "Last Name",
                showInCreate: true,
                mandatory: true,
                schema: nameSpaceSchema,
                maxChar: 50,
                editable: true,
            },
            {
                name: "phone",
                type: "text",
                label: "Phone",
                showInCreate: true,
                schema: phoneSchema,
                maxChar: 10,
                mandatory: true,
                editable: false,
            },
            {
                name: "email",
                type: "email",
                label: "Email",
                showInCreate: true,
                schema: emailSchema,
                editable: true,
            },
            {
                name: "password",
                type: "password",
                label: "Password",
                showInCreate: true,
                schema: passwordSchema,
                mandatory: true,
                editable: true,
            },
            {
                name: "roles",
                type: "searchmultiSelect",
                label: "Select Role",
                showInCreate: true,
                options: [],
                schema: multiSelectSchema,
                mandatory: true,
                editable: true,
            },
            {
                name: "dob",
                type: "dob",
                label: "DOB",
                showInCreate: true,
                editable: true,
            },
            {
                name: "description",
                type: "description",
                label: "Description",
                showInCreate: true,
                maxChar: 50,
                editable: true,
            },
            {
                name: "gender",
                type: "radio",
                label: "Gender",
                showInCreate: true,
                options: [
                    { label: "Male", value: "M" },
                    { label: "Female", value: "F" },
                    // { label: "Other", value: "Other" },
                ],
                editable: true,
            },
            {
                name: "profile_photo",
                type: "image",
                label: "Profile Image",
                showInCreate: true,
                editable: true,
            },
        ],
        searchableItems: [
            { label: "Admission Id", value: "admission_id" },
            { label: "Agent Name", value: "agent_name" },
            { label: "Phone", value: "phone" },
        ],
    },
};
