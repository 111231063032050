import React, { useEffect, useState } from "react";
import { Card } from "../Cards";
import { cardConfig } from "../config";
import chart from "../../../../assets/img/icons/unicons/chart-success.png";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";

export const SchoolOnboardCard = () => {
  const [schoolCount, setSchoolCount] = useState(null);
  const history = useHistory();
  const cardData = cardConfig.find((card) => card.type === "SchoolOnboard");

  const fetchDataFromAPI = async () => {
    try {
      const response = await fetch(
        //'https://inmobiusstaging.devinfinitylearn.in/api/v1/insights/get-count'
        "https://adminpreprod.inmobiusinfinitylearn.com/api/v1/insights/get-count"
        );
      const result = await response.json();
      const totalSchoolCount = result.data.total_school_count;
      setSchoolCount(totalSchoolCount);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleViewMoreClick = () => {
    fetchDataFromAPI();
    history.push(ROUTES.SCHOOL_ONBORADING_TABLE);
  };

  const handleDeleteClick = () => {
    // Handle Delete click
  };

  const dropdownButtons = [
    {
      text: "View More",
      link: "javascript:void(0);",
      onClick: handleViewMoreClick,
      buttonClassName: "",
    },
    {
      text: "Delete",
      link: "javascript:void(0);",
      onClick: handleDeleteClick,
      buttonClassName: "text-danger",
    },
  ];

  const cardDropdownConfig = {
    buttons: dropdownButtons,
    className: "my-dropdown",
  };

  return (
    <div className="col-2">
      <div>
        <Card
          title={cardData.title}
          iconSrc={chart}
          value={schoolCount}
          cardDropdownConfig={cardDropdownConfig}
        />
      </div>
    </div>
  );
};
