import React, { useState, useEffect } from "react";
import Table from "../table/Table";
import { ROUTES } from "../../constants/routes";
import { isAuthenticated } from "../../../utils/auth";
import { useHistory } from "react-router-dom";
import "./Dashboard.css";
import { Sidebar } from "../Sidebar/Sidebar";
import { Backdrop, CircularProgress } from "@mui/material";
import Navbar from "../Navbar";
import axios from "axios";
import { CONFIG_VARS, moduleConfig } from "../../constants/configuration";

export default function InsightDashboard() {
  const [sort, setSort] = useState("asc");
  const [currPage, setCurrPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [totalPages, setTotalPages] = useState(2);
  const [stuData, setStuData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [roleInfo, setRoleInfo] = useState([]);

  const [registerDate, setRegisterDate] = useState({
    date_range_start: "",
    date_range_end: "",
  });
  const [eventDate, setEventDate] = useState({
    date_range_start: "",
    date_range_end: "",
  });
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem("user_data"));
  const isSuperAdmin = CONFIG_VARS.roles["Inmboius Super Admin"].some((role) =>
    userData.roles.includes(role)
  );
  console.log(isSuperAdmin);
  if (!isAuthenticated() || !isSuperAdmin) {
    alert("You're not allowed to access the student page!");
    history.push(ROUTES.LOGIN);
  }
  useEffect(() => {
    fetchData();
  }, [currPage, limit]);
  useEffect(() => {
    const fetchRoleInfo = async () => {
      const roleUrl = `${CONFIG_VARS.gatwayApi}/role`;
      // const roleUrl=`https://gatewayapi.infinitylearn.com/api/v1/role`
      try {
        const response = await axios.get(roleUrl, {
          headers: {
            accept: "application/json",
            "product-id": "1000",
            "tenant-id": "1",
            "Content-Type": "application/json",
            platform: "web",
          },
        });
        if (response.status === 201) {
          setRoleInfo(response.data);
          return response.data;
        } else {
          console.error(
            "Failed to fetch role information. Response:",
            response
          );
          throw new Error("Failed to fetch role information");
        }
      } catch (error) {
        throw error;
      }
    };

    fetchRoleInfo();
  }, []);

  const fetchData = () => {
    // Prepare the payload based on user input
    const payload = {};

    if (registerDate.date_range_start && registerDate.date_range_end) {
      payload["registration_date"] = {
        date_range_start: `${registerDate.date_range_start}T00:00`,
        date_range_end: `${registerDate.date_range_end}T23:59`,
      };
    }

    if (eventDate.date_range_start && eventDate.date_range_end) {
      payload["event_date"] = {
        date_range_start: `${eventDate.date_range_start}T00:00`,
        date_range_end: `${eventDate.date_range_end}T23:59`,
      };
    }

    // Check if payload is empty
    if (Object.keys(payload).length === 0) {
      console.error("Error: Payload is empty. Please select date ranges.");
      return;
    } else {
      console.log(payload);
    }

    fetch(
      `${CONFIG_VARS.inmobius}/insights/students-filter?page=${currPage}&limit=${limit}&api_secret_key=${CONFIG_VARS.inmobiusKey}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "product-id": "100",
          "tenant-id": "99999",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setStuData(data.hits.hits);
        setTotalCount(data.hits.total.value);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };
  const downloadCSV = () => {
    setLoading(true);
    const payload = {};

    if (registerDate.date_range_start && registerDate.date_range_end) {
      payload["registration_date"] = {
        date_range_start: `${registerDate.date_range_start}T00:00`,
        date_range_end: `${registerDate.date_range_end}T23:59`,
      };
    }

    if (eventDate.date_range_start && eventDate.date_range_end) {
      payload["event_date"] = {
        date_range_start: `${eventDate.date_range_start}T00:00`,
        date_range_end: `${eventDate.date_range_end}T23:59`,
      };
    }

    // Check if payload is empty
    if (Object.keys(payload).length === 0) {
      console.error("Error: Payload is empty. Please select date ranges.");
      return;
    } else {
      console.log(payload);
    }

    fetch(
      `${CONFIG_VARS.inmobius}/insights/students-filter/export?api_secret_key=${CONFIG_VARS.inmobiusKey}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "product-id": "100",
          "tenant-id": "99999",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        window.location.href = data;
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };
  const isDisabled = () => {
    const registrationDatesFilled =
      registerDate.date_range_start && registerDate.date_range_end;
    const eventDatesFilled =
      eventDate.date_range_start && eventDate.date_range_end;

    return !(registrationDatesFilled || eventDatesFilled);
  };

  const isInvalidRegDateRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const currentDate = new Date();
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 15);
    const maxEndDate = new Date(startDate);
    maxEndDate.setDate(maxEndDate.getDate() + 7);

    return (
      startDate > currentDate ||
      startDate > endDate ||
      startDate < minDate ||
      endDate > currentDate ||
      endDate > maxEndDate
    );
  };

  const isInvalidEvntDateRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 15); // 15 days ago
    const maxEndDate = new Date(startDate);
    maxEndDate.setDate(maxEndDate.getDate() + 7); // 7 days from start date

    return (
      startDate > endDate || // Start date should be less than end date
      startDate < minDate || // Start date should not be more than 15 days in the past
      endDate > maxEndDate // End date should be within 7 days from start date
    );
  };

  return (
    <>
      <Navbar roleInfo={roleInfo} />
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ width: "80%" }}>
          <h3 className="dashboard-heading">Welcome to Insights Dashboard</h3>
          <div className="dashboard-container">
            <div className="dashboard-section">
              <label> Registration Date</label>
              <div className="date-input-container">
                <input
                  type="date"
                  placeholder="Start Date"
                  value={registerDate.date_range_start}
                  onChange={(e) => {
                    const dateValue = e.target.value;
                    if (
                      !isInvalidRegDateRange(
                        dateValue,
                        registerDate.date_range_end
                      )
                    ) {
                      setRegisterDate((prevState) => ({
                        ...prevState,
                        date_range_start: dateValue,
                      }));
                    }
                  }}
                  className="date-input"
                />
                <input
                  type="date"
                  placeholder="End Date "
                  value={registerDate.date_range_end}
                  onChange={(e) => {
                    const dateValue = e.target.value;
                    if (
                      !isInvalidRegDateRange(
                        registerDate.date_range_start,
                        dateValue
                      )
                    ) {
                      setRegisterDate((prevState) => ({
                        ...prevState,
                        date_range_end: dateValue,
                      }));
                    }
                  }}
                  className="date-input"
                />
              </div>
            </div>
            <div className="dashboard-section">
              <label> Event Date</label>
              <div className="date-input-container">
                <input
                  type="date"
                  placeholder="Start Date"
                  value={eventDate.date_range_start}
                  onChange={(e) => {
                    const dateValue = e.target.value;
                    if (
                      !isInvalidEvntDateRange(
                        dateValue,
                        eventDate.date_range_end
                      )
                    ) {
                      setEventDate((prevState) => ({
                        ...prevState,
                        date_range_start: dateValue,
                      }));
                    }
                  }}
                  className="date-input"
                />
                <input
                  type="date"
                  placeholder="End Date "
                  value={eventDate.date_range_end}
                  onChange={(e) => {
                    const dateValue = e.target.value;
                    if (
                      !isInvalidEvntDateRange(
                        eventDate.date_range_start,
                        dateValue
                      )
                    ) {
                      setEventDate((prevState) => ({
                        ...prevState,
                        date_range_end: dateValue,
                      }));
                    }
                  }}
                  className="date-input"
                />
              </div>
            </div>
            <div>
              <button
                disabled={isDisabled()}
                className="data-btn filter-btn"
                onClick={() => {
                  fetchData();
                  setLoading(true);
                }}
              >
                Get Data
              </button>
            </div>
            <button
              disabled={stuData.length === 0}
              type="button"
              className="exportBtn"
              onClick={downloadCSV}
            >
              Export Data
            </button>
          </div>
          <section className="section">
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="secondary" />
            </Backdrop>
            <Table
              navigationId={0}
              pageTitle="Students"
              data={stuData}
              loading={false}
              setCurrPage={setCurrPage}
              setTotalPages={setTotalPages}
              currPage={currPage}
              totalPages={totalPages}
              limit={limit}
              setLimit={setLimit}
              // setData={setData}
              setSort={setSort}
              sort={sort}
              totalCount={totalCount}
              setLoading={setLoading}
            />
          </section>
        </div>
      </div>
    </>
  );
}
