import React from "react";
import BacktoTop from "./BacktoTop";

const Footer = () => {
    return (
        <React.Fragment>
            <footer id="footer" className="footer">
                <div className="copyright">Copyright © 2023. All Rights Reserved 2023</div>
                <div className="credits"></div>
            </footer>
            <BacktoTop />
        </React.Fragment>
    );
};

export default Footer;
